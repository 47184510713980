import download from 'downloadjs';

let tokenStore;

const makeRequest = (endpoint, options) => fetch(`${process.env.DV_CLIENT_SERVER_DOMAIN}/${endpoint}`, options)
  .then(response => {
    if(response && response.status >= 200 && response.status < 300) {
      return response;
    }

    // This is not the best way to handle 401s from a user-experience perspective
    // as they will lose whatever they are doing and be redirected to the login,
    // but for now it's better than an impossible to figure out error page appearing.
    if(response.status === 401) {
      location.reload(); //eslint-disable-line no-restricted-globals
      return new Promise(() => {});
    }

    const error = new Error(response.statusText);
    error.response = response;

    throw error;
});

export default {
  get(endpoint, headers) {
    return tokenStore.getToken({aud: 'vizia.brandwatch.com'}).then(token =>
      makeRequest(endpoint, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            ...headers
          }
        })
    );
  },
  download(endpoint, filename, mimeType = 'application/json') {
    return tokenStore.getToken({aud: 'vizia.brandwatch.com'}).then(token => makeRequest(endpoint, {
      method: 'GET',
      headers: {
          'Content-Type': mimeType,
          Authorization: `Bearer ${token}`
      }
    })
  ).then(response => response.blob())
  .then(blob => download(blob, filename, mimeType));
  },
  post(endpoint, body) {
    return tokenStore.getToken({aud: 'vizia.brandwatch.com'}).then(token =>
      makeRequest(endpoint, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
          },
          body: JSON.stringify(body)
        })
    );
  },
  setTokenStore(store) {
    tokenStore = store;
  }
}
