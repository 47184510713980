import schema from './schema.json';
import appStore from '../../../../store/configure';

const populateResources = (circus, currentState, previousState = { value: '' }) => {
  if(currentState.value !== previousState.value) {
    const resources = appStore.getState().source.resourcesBySourceId[currentState.value];
    circus.updateField(currentState.resourceKey, {
      enum: resources,
      labels: resources
    });
  }
}

const init = (circus, key) => {
  const { sources } = appStore.getState().source;

  circus.updateField(key, {
    enum: sources,
    labels: sources
  });

  let currentState = circus.select(key);

  populateResources(circus, currentState);

  circus.store.subscribe(() => {
    const previousState = currentState;
    currentState = circus.select(key);
    populateResources(circus, currentState, previousState);
  });
}

export default {
  init,
  schema
};
