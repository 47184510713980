import React, { useEffect } from 'react';
import {
  Progress,
  Grid,
  GridCell,
  Heading,
  Text,
  Button,
  TextIcon
} from '@brandwatch/axiom-components';

import PageHeader from '../../Components/PageHeader';
import ViewCard from '../../Components/ViewCard';

import './style.css';

const ViewList = ({ loading, fetchViews, views, resetDraft, push }) => {

  useEffect(() => {
    resetDraft();
    fetchViews();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="view-list">
      <PageHeader>
        <Grid verticalAlign="middle" gutters="tiny">
          <GridCell shrink>
            <Button className="ax-button--icon-only" size="small" onClick={() => push('/')} style="quaternary"> {/* eslint-disable-line react/style-prop-object */}
              <Heading><TextIcon name="chevron-left" /> Back</Heading>
            </Button>
          </GridCell>
        </Grid>
      </PageHeader>

      <div className="view-list__body">
        <Heading textSize="headline" textUnderline>
          <Grid space="x2">
            <GridCell>
              All Saved Views
            </GridCell>
          </Grid>
        </Heading>

        <Grid space="x2" gutters="tiny">
        {
          !loading && views && views.length ?
          views.map(view => <ViewCard key={view.id} id={view.id} name={view.name} />) :
          <>
            {
              loading ?
                <Progress /> :
                <GridCell full textCenter>
                  <Text textSize="headtitle" textColor="body">
                    It appears you haven't created any views.
                  </Text>
                </GridCell>
            }
          </>
        }
        </Grid>
      </div>

    </div>
  );
}

export default ViewList;
