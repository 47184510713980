import React from 'react';
import { Heading, Grid, GridCell } from '@brandwatch/axiom-components';
import './style.css';

export default () => (
  <div className="non-bcr-user__page">
    <Grid>
      <GridCell textCenter full>
        <Heading textSize="display1">Unfortunately legacy Brandwatch clients do not have access to this application.</Heading>
      </GridCell>
      <GridCell textCenter full>
        <Heading textSize="headline">Please contact your customer success manager to find out about migrating to Consumer Research</Heading>
      </GridCell>
    </Grid>
  </div>
);
