import { connect } from 'react-redux';
import { push } from 'connected-react-router'
import { fetchView } from '../../store/views';
import ViewCard from './ViewCard';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  fetchView,
  push
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewCard);
