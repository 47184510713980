import jwtDecode from 'jwt-decode';
import TokenStore from 'donny-auth';

const {
  DV_CLIENT_AUTH_STORE_DOMAIN
} = process.env;

const aud = 'vizia.brandwatch.com'

export default class AuthFacade {
  constructor(domain = DV_CLIENT_AUTH_STORE_DOMAIN) {
    this.store = new TokenStore(domain);

    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getUserProfile = this.getUserProfile.bind(this);

    AuthFacade.instance = this;
  }

  login() {
    window.location.href = this.store.loginUrl;
  }

  logout() {
    return this.store.removeToken({aud});
  }

  isAuthenticated() {
    return this.store.getToken({aud});
  }

  async getUserProfile() {
    const user = await jwtDecode(await this.store.getToken({aud}));
    const meRequest = await fetch(`${process.env.DV_CLIENT_BW_API_ROOT}/me?access_token=${user.api2AccessToken}`);
    const me = await meRequest.json();

    return {
      ...user,
      bcr: !!me.client.crimsonMode
    }
  }
}
