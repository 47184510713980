import { connect } from 'react-redux';
import Table from './Table';

const mapStateToProps = ({table}) => ({
  data: table.tableData,
  loading: table.loading
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Table);
