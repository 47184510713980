import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { setTableMetaData, setTableData, toggleTableLoading } from '../../store/table';
import { saveView, fetchView } from '../../store/views';
import Controls from './Controls';

const mapStateToProps = ({views}) => ({
  draft: views.draft
});

const mapDispatchToProps = {
  setTableMetaData,
  setTableData,
  toggleTableLoading,
  fetchView,
  saveView
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Controls));
