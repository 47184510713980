import { createAction, handleActions } from 'redux-actions';
import apiRequest from '../utils/apiRequest';

const initialState = {
  loading: false,
  views: [],
  draft: {}
};

const removeColumnTransform = (transforms = []) => transforms.reduce((acc, transform) => {
  if(transform.subtype !== 'showColumns') {
    acc.push(transform);
  }
  return acc;
}, [])

export const requestView = createAction('REQUEST_VIEW');
export const receiveView = createAction('RECEIVE_VIEW', view => ({
  ...view,
  source: view.source || {},
  transforms: removeColumnTransform(view.transforms)
}));

export const fetchView = (id) => {
  return dispatch => {
    dispatch(requestView());
    return apiRequest.get(`views/${id}`)
      .then(res => res.json())
      .then(view => dispatch(receiveView(view)));
  }
}

export const requestViews = createAction('REQUEST_VIEWS');
export const receiveViews = createAction('RECEIVE_VIEWS');

export const fetchViews = () => {
  return dispatch => {
    dispatch(requestViews());
    return apiRequest.get('views')
      .then(res => res.json())
      .then(views => dispatch(receiveViews(views)));
  }
};

export const requestCreateView = createAction('REQUEST_CREATE_VIEW');
export const createViewSuccess = createAction('CREATE_VIEW_SUCCESS');

export const createView = (name, presenter) => {
  return dispatch => {
    dispatch(requestCreateView());
    return apiRequest.post('views', {name, presenter})
      .then(res => res.json())
      .then(view => {
        dispatch(createViewSuccess(view));
        return view;
      });
  }
}

export const requestSaveView = createAction('REQUEST_SAVE_VIEW');
export const saveViewSuccess = createAction('SAVE_VIEW_SUCCESS');

export const saveView = ({id, name, presenter, source, transforms}) => {
  return dispatch => {
    dispatch(requestSaveView());
    return apiRequest.post(`views/${id}/data`, {
      id,
      name,
      presenter,
      source,
      transforms
    })
      .then(res => res.json())
      .then(view => {
        dispatch(saveViewSuccess({
          id,
          name,
          presenter,
          source,
          transforms
        }));

        return view;
      });
  }
};

export const setDraftViewTransforms = createAction('SET_DRAFT_VIEW_TRANSFORMS');

export const clearDraft = createAction('CLEAR_DRAFT_VIEW');

export default handleActions({
  [clearDraft]: (state) => ({
    ...state,
    draft: {}
  }),
  [requestViews]: (state) => ({
    ...state,
    loading: true
  }),
  [receiveViews]: (state, { payload }) => ({
    ...state,
    loading: false,
    views: payload
  }),
  [receiveView]: (state, { payload }) => ({
    ...state,
    loading: false,
    draft: {
      ...payload,
      transforms: Array.isArray(payload.transforms) ? payload.transforms : []
    }
  }),
  [createViewSuccess]: (state, { payload }) => ({
    ...state,
    views: state.views.concat({id: payload.id, name: payload.name}),
    draft: {
      ...payload,
      source: {},
      transforms: []
    }
  }),
  [saveViewSuccess]: (state, { payload }) => ({
    ...state,
    draft: {
      ...state.draft,
      ...payload
    }
  }),
  [setDraftViewTransforms]: (state, { payload }) => ({
    ...state,
    draft: {
      ...state.draft,
      transforms: payload
    }
  })
}, initialState);
