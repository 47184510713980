import React, { useState } from 'react';
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Heading,
  TextInput,
  Select,
  SelectOptionGroup,
  SelectOption
} from '@brandwatch/axiom-components';

const NewViewDialog = ({isOpen, onRequestClose, createView, push}) => {
  const [name, setName] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const [presenterValue, setPresenter] = useState('');

  const presenters = {
    spreadsheet: 'Spreadsheet'
  };

  const handleSubmit = () => {
    if(name && selectedValue) {
      createView(name, selectedValue)
        .then(createdView => {
          push(`/views/${createdView.id}/edit`);
        });
    }
  }

  const handleSelectChange = value => {
    setSelectedValue(value);
    setPresenter(presenters[value]);
  }

  return (
    <Dialog isOpen={isOpen} onRequestClose={onRequestClose} width="30rem">
      <DialogHeader>
        <Heading textSize="headtitle">
          New View
        </Heading>
      </DialogHeader>
      <DialogBody>
        <TextInput label="Name" value={name} onChange={e => setName(e.target.value) } />

        <Select
          label="Presenter"
          onChange={() => {}}
          onSelect={value => handleSelectChange(value)}
          selectedValue={selectedValue}
          value={presenterValue}
        >
          <SelectOptionGroup>
            <SelectOption value="spreadsheet">
              Spreadsheet
            </SelectOption>
          </SelectOptionGroup>
        </Select>
      </DialogBody>
      <DialogFooter textRight>
        <ButtonGroup>
          <Button style="secondary" onClick={onRequestClose}>Cancel</Button> {/* eslint-disable-line react/style-prop-object */}
          <Button onClick={handleSubmit}>Create & Open View</Button>
        </ButtonGroup>
      </DialogFooter>
    </Dialog>
  );
}

export default NewViewDialog;
