import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch, Redirect } from 'react-router';
import store, { history } from './store/configure';
import {
  Platform,
  Canvas,
  Dock,
  DockFooter,
  DockItem,
  DockIconLink
} from '@brandwatch/axiom-components';

import { UserMenu } from '@brandwatch/axiom-composites';

import EditView from './Layouts/EditView';
import ViewList from './Layouts/ViewList';
import Home from './Layouts/Home';
import NonBCRUser from './Layouts/NonBCRUser';
import AuthFacade from './utils/AuthFacade';

const Router = () => {
  const { user } = store.getState();

  return (
    <ConnectedRouter history={history}>
      <Platform>
        <Dock>
          <Route exact path='/' children={({match, history}) => (
            <DockIconLink
              icon="home"
              title="Home"
              active={ !!match }
              onClick={ () => history.push('/') }
            />
          )} />

          <Route exact path='/views' children={({match, history}) => (
            <DockIconLink
              icon="folder"
              title="Views"
              active={ !!match }
              onClick={ () => history.push('/views') }
            />
          )} />
          <DockFooter>
            <DockItem>
              <UserMenu
                email={user.email}
                lastName=''
                onLogout={() => AuthFacade.instance.logout().then(() => location.reload())} //eslint-disable-line no-restricted-globals
                firstName={user.name} />
            </DockItem>
          </DockFooter>
        </Dock>
        <Canvas>
          <Switch>
            {
              user.bcr ?
              <>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route exact path="/views">
                  <ViewList />
                </Route>
                <Route path="/views/:viewId/edit">
                  <EditView />
                </Route>
              </> :
              <Route exact path="/">
                <NonBCRUser />
              </Route>
            }
            <Route>
              <Redirect to="/" />
            </Route>
          </Switch>
        </Canvas>
      </Platform>
    </ConnectedRouter>
  );
};

export default Router;
