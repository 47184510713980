import { connect } from 'react-redux';
import { push } from 'connected-react-router'
import { resetTable } from '../../store/table';
import { fetchViews, clearDraft } from '../../store/views';
import ViewList from './ViewList';

const mapStateToProps = ({views}) => ({
  loading: views.loading,
  views: views.views
});

const resetDraft = () => dispatch => {
  dispatch(clearDraft());
  dispatch(resetTable());
}

const mapDispatchToProps = {
  fetchViews,
  push,
  resetDraft
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewList);
