import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from "react-router";
import {
  Separator,
  Grid,
  GridCell,
  Button
} from '@brandwatch/axiom-components';
import { Provider } from 'react-redux';
import Renderer from '@vizia/control-circus-renderer--axiom';

import apiRequest from '../../utils/apiRequest';
import buildCircus from '../../utils/buildCircus';
import buildColumnTransforms from '../../utils/buildColumnTransforms';

import './style.css';

function Controls({
  draft,
  setTableData,
  setTableMetaData,
  toggleTableLoading,
  fetchView,
  saveView,
  match
}) {
  const [ctrlCircus, setCircus] = useState();

  const loadData = async () => {
    if (ctrlCircus && ctrlCircus.validate()) {
      const {
        source,
        resource,
        urlParams,
        queryParams,
        serverParams
      } = ctrlCircus.getValues().options;

      toggleTableLoading(true);

      const transforms = [...draft.transforms, buildColumnTransforms(serverParams.projectColumns)];

      const retrievedDataResponse = await apiRequest.post(`views/${match.params.viewId}/data`, {
        name: draft.name,
        presenter: draft.presenter,
        source: {
          id: source,
          resource,
          params: {
            urlParams,
            queryParams: {
              ...queryParams,
              pageSize: 500
            },
            serverParams
          }
        },
        transforms
      });

      setTableMetaData({
        source,
        resource,
        queryId: queryParams.queryId,
        startDate: queryParams.startDate,
        endDate: queryParams.endDate
      });

      setTableData(await retrievedDataResponse.json());
      toggleTableLoading(false);
    }
  };

  const handleSaveView = useCallback(async () => {
    if(ctrlCircus && ctrlCircus.validate()) {
      const {
        name,
        source,
        resource,
        queryParams,
        filter,
        urlParams,
        serverParams
       } = ctrlCircus.getValues().options;

      await loadData();

      saveView({
        id: Number(match.params.viewId),
        name,
        presenter: draft.presenter,
        source: {
          id: source,
          resource: resource,
          params: {
            queryParams,
            urlParams,
            filter,
            serverParams
          }
        },
        transforms: draft.transforms
      })
    };
  }, [draft]); //eslint-disable-line react-hooks/exhaustive-deps


  const handleExportView = useCallback(async () => {
    if(ctrlCircus && ctrlCircus.validate()) {
      await loadData();
      await apiRequest.download(`views/${match.params.viewId}/data.csv`, draft.name, 'text/csv');
    };
  }, [draft, ctrlCircus]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    loadData();
  }, [ctrlCircus]); //eslint-disable-line react-hooks/exhaustive-deps

  // instantiate circus on mount
  useEffect(() => {
    if(draft.name && !ctrlCircus) {
      setCircus(buildCircus(draft));
    } else if (!draft.name) {
      fetchView(match.params.viewId);
    }
  }, [ctrlCircus, draft]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="controls">
      <Grid className="controls__header" space="x2" gutters="tiny">
        <GridCell full>
          <Grid className="controls__header-content" verticalAlign="middle">
            <GridCell>
              <Button full onClick={() => handleExportView()}> {/* eslint-disable-line react/style-prop-object */}
                  Export CSV
                </Button>
            </GridCell>
            <GridCell>
              <Button full onClick={() => handleSaveView()}> {/* eslint-disable-line react/style-prop-object */}
                  Save
                </Button>
            </GridCell>
          </Grid>
        </GridCell>
        <GridCell full>
          <Separator />
        </GridCell>
      </Grid>
      {
        ctrlCircus &&
        <Provider store={ctrlCircus.store}>
          <Renderer
            circus={ctrlCircus}
            onBlur={() => loadData()}
          />
        </Provider>
      }
    </div>
  );
}

export default withRouter(Controls);
