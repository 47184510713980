import React, { createRef } from 'react';
import clone from 'clone';
import { HotTable } from '@handsontable/react';
import { Progress } from '@brandwatch/axiom-components';
import buildViewTransforms from '../../utils/buildViewTransforms';

import 'handsontable/dist/handsontable.css';

import './style.css';

const {
  DV_CLIENT_HOT_LICENSE
} = process.env;

const Table = ({ loading, data, settings }) => {
  const hotRef = createRef();

  return (
    <div className="table" id="hot-app">
      {
        !loading && data ?
          <HotTable
            settings={{
              ...settings,
              afterChange: buildViewTransforms,
              cells: (row) => {
                const cellProperties = {}
                if (row !== 0) {
                  cellProperties.readOnly = true;
                }
                return cellProperties;
              }
            }}
            ref={hotRef}
            colHeaders={true}
            rowHeaders={true}
            data={clone(data)}
            dateFormat={'YYYY/MM/DD'}
            width="100%"
            height="100%"
            licenseKey={DV_CLIENT_HOT_LICENSE}
            undo
          /> :
          <div className="no-data">
            <span>
              {
                loading ?
                  <Progress /> :
                  'Please pick a project and query to view mentions.'
              }
            </span>
          </div>
      }
    </div>
  );
};

export default Table;
