import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import Router from './Router';
import AuthFacade from './utils/AuthFacade';
import apiRequest from './utils/apiRequest';
import store from './store/configure';
import { setUser } from './store/user';
import { fetchSources } from './store/source';

const authFacade = new AuthFacade();
apiRequest.setTokenStore(authFacade.store);

authFacade.isAuthenticated().then(token => {
  if(!token) {
    return authFacade.login()
  }

  return authFacade.getUserProfile();
}).then(user => {
  store.dispatch(setUser(user));
  store.dispatch(fetchSources());

  render(
    <Provider store={store}>
      <Router />
    </Provider>,
    document.getElementById('root')
  );
});
