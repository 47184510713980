import schema from './schema.json';
import tableStore from '../../../../store/configure';
import apiRequest from '../../../../utils/apiRequest.js';

const buildOptions = async ({
  currentSource,
  currentResource,
  currentHeaders = {},
  circus,
  key
}) => {
  const resourceSchemaRequest = await apiRequest.get(`sources/${currentSource}/resources/${currentResource}/schema`);
  const { properties } = await resourceSchemaRequest.json();
  const schemaProps = Object.keys(properties);

  const labels = schemaProps.reduce((transformedLabels, prop) => {
    if(currentHeaders[prop]) {
      transformedLabels.push(currentHeaders[prop]);
    } else {
      transformedLabels.push(prop);
    }
    return transformedLabels;
  }, []);

  circus.updateField(key, {
    enum: schemaProps,
    labels
  });
}

const init = async (circus, key) => {
  let currentSource = circus.select('/options/data/source').value;
  let currentResource = circus.select('/options/data/resource').value;

  if(currentSource && currentResource) {
    buildOptions({
      currentSource,
      currentResource,
      circus,
      key
    });
  }

  circus.store.subscribe(() => {
    const previousSource = currentSource;
    currentSource = circus.select('/options/data/source').value;

    const previousResource = currentResource;
    currentResource = circus.select('/options/data/resource').value;

    if(currentSource && currentResource && (currentResource !== previousResource || currentSource !== previousSource)) {
      buildOptions({
        currentSource,
        currentResource,
        circus,
        key
      });
    }
  });

  let currentHeaders = tableStore.getState().table.tableData[0] || {};
  tableStore.subscribe(() => {
    const previousHeaders = currentHeaders || {};
    currentHeaders = tableStore.getState().table.tableData[0] || {};
    if(Object.values(currentHeaders).some(header => !Object.values(previousHeaders).includes(header))) {
      buildOptions({
        currentSource: circus.select('/options/data/source').value,
        currentResource: circus.select('/options/data/resource').value,
        currentHeaders,
        circus,
        key
      });
    }
  });
}

export default {
  init,
  schema
};
