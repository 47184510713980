import React from 'react';
import { Card, CardContent, Heading, Grid, GridCell, Icon } from '@brandwatch/axiom-components';

const ViewCard = ({name, id, fetchView, push}) => {
  const goToView = id => {
    fetchView(id).then(() => {
      push(`/views/${id}/edit`);
    });
  }

  return (<GridCell full>
    <Card onClick={() => goToView(id)}>
      <CardContent>
        <Grid verticalAlign="middle" gutters="tiny">
          <GridCell fit>
            <Grid gutters="tiny">
              <GridCell full>
                <Heading textSize="headtitle">{name}</Heading>
              </GridCell>
            </Grid>
          </GridCell>
          <GridCell shrink>
            <Icon name="chevron-right" />
          </GridCell>
        </Grid>
      </CardContent>
    </Card>
  </GridCell>);
};

export default ViewCard;
