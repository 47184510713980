import bwPlugins from '@vizia/bw-circus';
import source from './source';
import projectColumns from './projectColumns';
import orderBy from './orderBy';
import dateRange from './dateRange';
import bcrFilters from './bcrFilters';

export default [
  ...bwPlugins,
  projectColumns,
  orderBy,
  source,
  dateRange,
  bcrFilters
]
