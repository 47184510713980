import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Progress,
  Grid,
  GridCell,
  Heading,
  Paragraph,
  Text
} from '@brandwatch/axiom-components';
import NewViewDialog from '../../Components/NewViewDialog';
import ViewCard from '../../Components/ViewCard';

import './style.css';

const Home = ({ loading, fetchViews, views, resetDraft, push, isBcrUser }) => {
  const [dialogIsOpen, showDialog] = useState(false);

  useEffect(() => {
    resetDraft();
    fetchViews();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="view-list__page">
      <NewViewDialog
        isOpen={dialogIsOpen}
        onRequestClose={() => showDialog(false)}
      />
      <Grid>
        <GridCell>
          <Card border onClick={() => showDialog(true)}>
            <CardContent textCenter>
              <Heading textStrong textSize="headtitle">Design a new content view</Heading>
              <Paragraph textColor="subtle">Define and shape data from a variety of sources</Paragraph>
            </CardContent>
          </Card>
        </GridCell>

        <GridCell>
          <Card border onClick={() => push('/views')}>
            <CardContent textCenter>
              <Heading textStrong textSize="headtitle">Open an existing view</Heading>
              <Paragraph textColor="subtle">Open one of your previously defined views</Paragraph>
            </CardContent>
          </Card>
        </GridCell>
      </Grid>

      <Heading textSize="headline" textUnderline>
        <Grid space="x2">
          <GridCell>
            Recently Created Views
          </GridCell>
        </Grid>
      </Heading>

      <Grid space="x2" gutters="tiny">
      {
        !loading && views && views.length ?
        views.slice(0,5).map(view => <ViewCard key={view.id} id={view.id} name={view.name} />) :
        <>
          {
            loading ?
              <Progress /> :
              <GridCell full textCenter>
                <Text textSize="headtitle" textColor="body">
                  It appears you haven't created any views.
                </Text>
              </GridCell>
          }
        </>
      }
      </Grid>
    </div>
  );
}

export default Home;
