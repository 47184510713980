import React from 'react';
import {
  Heading,
  Grid,
  GridCell,
  Button,
  ButtonIcon
} from '@brandwatch/axiom-components';

import Controls from '../../Components/Controls';
import Table from '../../Components/Table';
import PageHeader from '../../Components/PageHeader';

import './style.css';

const EditView = ({
  name,
  returnToHome
}) => (
  <div className="edit-view">
    <PageHeader>
      <Grid verticalAlign="middle" gutters="tiny">
        <GridCell shrink>
          <Button className="ax-button--icon-only" size="small" onClick={returnToHome} style="quaternary"> {/* eslint-disable-line react/style-prop-object */}
            <ButtonIcon name="chevron-left" size="2rem" />
          </Button>
        </GridCell>
        <GridCell shrink className='edit-view__separator-wrapper'>
          <div className="edit-view__separator"></div>
        </GridCell>
        <GridCell fit>
          <Heading textSize="headline" textStrong textColor="body">{ name }</Heading>
        </GridCell>
      </Grid>
    </PageHeader>
    <div className="edit-view__body">
      <Controls />
      <Table />
    </div>
  </div>
);

export default EditView;
