import { createAction, handleActions } from 'redux-actions';

const initialState = {};

export const setUser = createAction(
  'SET_USER',
  (user) => ({
    email: user.email,
    clientId: user.clientId,
    name: `${user.firstName} ${user.lastName}`,
    token: user.api2AccessToken,
    bcr: user.bcr
  })
)

export default handleActions({
  [setUser]: (state, { payload }) => payload,
}, initialState);
