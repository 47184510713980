/**
 * Plugin to ensure that the user can order by any of the currently selected project columns.
 * It looks at the current value of the selected project columns and uses that to populate the list of options to sort by
 */
import schema from './schema.json';
import tableStore from '../../../../store/configure';

const populateColumnOptions = (circus, key, tableData) => {
  circus.updateField(key, {
    enum: Object.keys(tableData[0]),
    labels: Object.values(tableData[0])
  });
};

const valuesHaveChanged = (currentValues, previousValues) => currentValues.some((value, index) => previousValues[index] !== value);

const init = (circus, key) => {
  let currentState = circus.select(key);
  let currentProjectColumnsState = circus.select(currentState.dependsOn[0]);

  circus.updateField(key, {
    enum: currentProjectColumnsState.value,
    labels: currentProjectColumnsState.value
  });

  circus.store.subscribe(() => {
    currentState = circus.select(key);
    currentProjectColumnsState = circus.select(currentState.dependsOn[0]);

    if (!currentProjectColumnsState.value && currentState.value) {
      circus.updateField(key, {
        value: undefined,
        enum: [],
        labels: []
      });
    }
  });

  let currentTableData = tableStore.getState().table.tableData;
  tableStore.subscribe(() => {
    const previousTableData = currentTableData;
    currentTableData = tableStore.getState().table.tableData;

    if(currentTableData.length && (
          !previousTableData.length ||
          previousTableData[0].length !== currentTableData[0].length ||
          valuesHaveChanged(Object.values(currentTableData[0]), Object.values(previousTableData[0]))
    )) {
      populateColumnOptions(circus, key, currentTableData);
    }
  });
};

export default {
  init,
  schema
};
