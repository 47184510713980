import store from '../store/configure';
import { setDraftViewTransforms } from '../store/views';
import { changeTableHeader } from '../store/table';
import clone from 'clone';

export const squashTransforms = (existingTransforms = [], newTransforms = []) => newTransforms.reduce((squashedTransforms, newTransform) => {
  const oldTransformIndex = squashedTransforms.findIndex(existingTransform => {
    return existingTransform.params.prop === newTransform.params.prop &&
      existingTransform.params.row === newTransform.params.row
  });

  if(oldTransformIndex >= 0) {
    squashedTransforms[oldTransformIndex] = newTransform;
  } else {
    squashedTransforms.push(newTransform);
  }

  return squashedTransforms;
}, [...existingTransforms]);

export const transformTableChanges = changes => changes.map(change => ({
  type: 'spreadsheet', // presenter
  subtype: 'changeValue', // transform
  params: {
    row: change[0],
    prop: change[1],
    value: change[3]
  }
}));

const updateColumnHeaders = squashedTransforms => squashedTransforms.forEach(transform => {
  const existingHeaders = store.getState().table.tableData[0] && Object.keys(store.getState().table.tableData[0]);
  if(transform.params.row === 0 && transform.subtype === 'changeValue' && existingHeaders.includes(transform.params.prop)) {
    store.dispatch(changeTableHeader({prop: transform.params.prop, value: transform.params.value}));
  }
});

const buildViewTransforms = (changes, source) => {
  const existingTransforms = clone(store.getState().views.draft.transforms);

  switch(source) {
    case 'loadData':
      break;
    default:
      const squashedTransforms = squashTransforms(existingTransforms, transformTableChanges(changes));
      store.dispatch(setDraftViewTransforms(squashedTransforms));
      updateColumnHeaders(squashedTransforms);
  }
};

export default buildViewTransforms;
