import { connect } from 'react-redux';
import { push } from 'connected-react-router'
import { createView } from '../../store/views';
import NewViewDialog from './NewViewDialog';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  createView,
  push
};

export default connect(mapStateToProps, mapDispatchToProps)(NewViewDialog);
