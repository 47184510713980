import { createAction, handleActions } from 'redux-actions';
import apiRequest from '../utils/apiRequest';

const initialState = {
  sources: [],
  currentSource: '',
  resourcesBySourceId: {},
  resourceSchema: {}
};

export const requestSources = createAction('REQUEST_SOURCES');
export const receiveSources = createAction('RECEIVE_SOURCES');
export const fetchSources = () => {
  return dispatch => {
    dispatch(requestSources());
    return apiRequest.get('sources')
      .then(res => res.json())
      .then(sources => dispatch(receiveSources(sources)));
  }
};

export default handleActions({
  [receiveSources]: (state, { payload }) => ({
    ...state,
    sources: payload.map(source => source.id),
    resourcesBySourceId: payload.reduce((sourceResourceMap, source) => ({
      ...sourceResourceMap,
      [source.id]: source.resources
    }), {})
  })
}, initialState);
