import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { resetTable } from '../../store/table';
import { clearDraft } from '../../store/views';
import EditView from './EditView';

const mapStateToProps = ({views}) => ({
  name: views.draft && views.draft.name
});

const returnToHome = () => dispatch => {
  dispatch(resetTable());
  dispatch(clearDraft());
  dispatch(push('/'));
}

const mapDispatchToProps = {
  returnToHome
};

export default connect(mapStateToProps, mapDispatchToProps)(EditView);
