import Circus from '@vizia/control-circus';
import AuthFacade from './AuthFacade';
import plugins from './circus/plugins';

import controlsSchema from './circus/schema.json';

const {
  DV_CLIENT_BW_API_ROOT
} = process.env;

export default ({source, name}) => {
  if(name) {
    return new Circus(controlsSchema, {
      externs: {
        'bw-circus/bw-api-root': () => DV_CLIENT_BW_API_ROOT,
        'bw-circus/bw-api-token': () => AuthFacade.instance
          .getUserProfile()
          .then((profile) => profile.api2AccessToken)
      },
      initialFormData: {
        options: {
          name,
          source: source.id,
          resource: source.resource,
          ...source.params
        }
      },
      plugins
    });
  }
}
